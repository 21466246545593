"use client"


import React, { useEffect, useState } from 'react'
import './nbar.css'
import { FaBars } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";
import Somfinlogo from '../../assets/somfinlogo.png'

const data = [
    {id: 1, link: "/individual", caption: 'Individual'},
    {id: 2, link: "/business", caption: 'Business'},
    {id: 3, link: "/planner", caption: 'Explore'},
    
    
]

const Navbar = () => {
   const [navOpen, setNavOpen] = useState(false);

   useEffect(() => {
     if(window.innerWidth > 1240) {
        setNavOpen(true);
     }  
    }, [navOpen])

   const closeNavHandler = () => {
    if(window.innerWidth <= 1240 ) {
        setNavOpen(true);
    }
   }


  return (
    <div className="nbar"> 
        <a href="/" className="nlogo" onClick={closeNavHandler}>SOMFIN
        <img src={Somfinlogo} alt="Somfin Logo" className="somfin-logo" />
        </a>
        <div className="ncontainer">
            {navOpen && <ul className="nItems">
                {
                    data.map(({id,link,caption}) => <li key={id}>
                       <a href={link} className="nbas">{caption}</a>
                    </li>)
                }
            </ul>}
            <div className="nbtns">
                <button className="navbuton" onClick={() => setNavOpen(!navOpen)}>{navOpen ?<IoCloseOutline /> : <FaBars />}</button>

            </div>
        
        </div>
    </div>
  )
}

export default Navbar