import React, { useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Demo from '../../../assets/somfinedemo.mp4';

import './homepg.css'; 
const Homepag = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger); 

    var tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#main",
        start: "50% 50%",
        end: "150% 50%",
        scrub: 2,
        pin: true
      }
    });

    tl.to("#center", {
      height: "100vh",
    }, 'a')
    .to("#top",{
      top: "-50%",
   },'a')
   .to("#bottom",{
      bottom: "-50%",
   },'a')
   .to("#top-h1",{
    top:"60%"
   },'a')
   .to ("#bottom-h1",{
    bottom:"-30%"
   }, 'a')
   
  .to("#center-h1",{
     top: "-30%"
  },'a')
      .to(".content", {
        delay: -0.2,
        marginTop: "0%"
      });
  }, []); 
  return (
    <div id="main">
      
      <div id="top">
        <h1 id="top-h1">Explore</h1>
      </div>
      <div id="center">
        <div className="content">
          <h4>SOMFIN</h4>
  
          <h3><i>Browse</i> the world that defines <i>wealth</i> and build the trust.</h3>
          <a href={Demo} onPlay className='btn'target="_blank"rel='noopener noreferrer'>Watch Demo</a>
          <h2>Next GEN</h2>
        </div>
        

      
      </div>
      
    
      <div id="bottom">
        <h1 id="bottom-h1">the Opportunity.</h1>
      </div>
    </div>
  );
}

export default Homepag;