import React, { useEffect } from 'react';
import './thirdpg.css'

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import img1 from '../../assets/thirdpg/onee.png'
import img2 from '../../assets/thirdpg/twoo.png'
import img3 from '../../assets/thirdpg/three.png'
import img4 from '../../assets/thirdpg/fourr.png'


const Thirdpg = () => {
    useEffect(() => {
      gsap.registerPlugin(ScrollTrigger); 
  
      var tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: "#part-5",
          start: "50% 50%",
          end: "150% 50%",
          scrub: 2,
          pin: true
        }
      });
  
      tl2.to("#scroll-1", 
        {
          bottom: "60vh",
        })
        .to("#scroll-1", 
        {
          opacity: 0,
        })
        .to("#scroll-2", 
        {
          opacity: 1,
        }, 'arrf')
        .to(".phone-img", 
        {
          x: "-26%",
        }, 'arrf')
        .to("#scroll-2", 
        {
          bottom: "60vh",
        })
        .to("#scroll-2", 
        {
          opacity: 0,
        })
        .to("#scroll-3", 
        {
          opacity: 1,
        }, 'arrs')
        .to(".phone-img", 
        {
          x: "-52%",
        }, 'arrs')
        .to("#scroll-3", 
        {
          bottom: "60vh",
        })
        .to("#scroll-3", 
        {
          opacity: 0,
        })
        .to("#scroll-4", 
        {
          opacity: 1,
        }, 'arrt')
        .to(".phone-img", 
        {
          x: "-79%",
        }, 'arrt')
        
    }, []);
  return (
    <div id="part-5" class="part-same">
      <div class="lft-part-5">
         <div id="scroll-1" class="scroll-txt">
            <h1>We’re <br/>here for you.</h1>
            <p>Gain complete control over your Finances with SOMFIN. Receive category-based analysis of your expenditures, detect hidden charges, and track your credit limit in real-time.            </p>
          </div>
          <div id="scroll-2" class="scroll-txt">
            <h1>Start your
                <br/>conquering journey.</h1>
            <p>Use SOMFIN to gain the access of uneven parts of your financial spectrum from expenditure analysis to all the in depth detailing and forecasting of your financial tracks.            </p>
           </div>
          <div id="scroll-3" class="scroll-txt">
            <h1>Specially for
                <br/>you.</h1>
            <p>Get access to the tools by SOMFIN, a member-exclusive selection of products and experience at special prices that complement your taste. This is a great way to enjoy your life financially stress free. </p>
           </div>
          <div id="scroll-4" class="scroll-txt">
            <h1>More cash
                <br/>in your pockets.</h1>
            <p>Build an enriched financial health with SOMFIN platform. Complete 360 degree security from any financial risk added with in depth management support in instant access mode.            </p>
          </div>
        </div>
      <div class="rght-part-5">
        <div class="phone">
            <div class="phone-img">
                <img src={img1} alt="image1" />
                <img src={img2} alt="image2" />
                <img src={img3} alt="image3" />
                <img src={img4} alt="image4" />
                
            </div>
        </div>
      </div>
   </div>




)
}

export default Thirdpg