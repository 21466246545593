import React from 'react'

const privacy = () => {
  return (
    <div className='container privacy__container'>
       <h2>SOMFIN PRIVACY POLICY</h2>
       <h5>LAST UPDATED 15 FEB 2024</h5>
        <p>
                Overview

                This Privacy Statement applies to SOMFIN and aims to provide you with information regarding our use of your Personal Information when you visit our website.
                At SOMFIN, we are committed to safeguarding the privacy of our website visitors. This Privacy Policy outlines how we collect, use, and protect your personal information when you visit our website and interact with our services.
        </p>
        <h5>Information We Collect:</h5>
        <p>
                

                Cookies: We use cookies to enhance your browsing experience and collect anonymous usage data. These cookies may track your IP address, browser type, device information, and browsing behaviour on our website. You can choose to disable cookies through your browser settings, although this may affect your experience on our website.

                Feedback Form: When you submit feedback or contact us through the form provided on our website, we collect the following information:

                Sender Name
                Email Address
                Message
        </p>
        <h5> How We Use Your Information:</h5>
        <p>      

                Improving User Experience: The information collected through cookies helps us analyse website traffic, understand user preferences, and optimise our website to better serve your needs.

                Feedback and Communication: The information provided through the feedback form is used to respond to your inquiries, address your feedback, and communicate with you about our services, offers, or promotions if you have consented to receive such communications.

                Personalization: We may use the information collected to personalise your experience on our website, such as displaying relevant content based on your browsing behaviour or preferences.
        </p>

        <h5> Data Security:</h5>
        <p>
                We are committed to ensuring the security of your personal information. We implement industry-standard security measures to protect against unauthorised access, alteration, disclosure, or destruction of your data.We fully adhere to the regulations outlined in the India Digital Personal Data Protection Act 2023 and the Information Technology Act, 2000, ensuring compliance with all relevant data protection and privacy laws.
        </p>
        <h5> Can Children Use Our Services?</h5>
        <p>        
                
                We do not knowingly collect information, including Personal Information, from children under the age of 18 or other individuals who are not legally able to use our Services. If we obtain actual knowledge that we have collected Personal Information from someone not allowed to use our Services, we will promptly delete it, unless we are legally obligated to retain such data.

                Please contact us if you believe that we have mistakenly or unintentionally collected information from someone not allowed to use our Services.

                We do not sell to third parties for money or share Personal Information of anyone under 18 years of age.

        </p>        

        <h5>Third-Party Disclosure:</h5>
        <p>

                We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except where required by law or as necessary to provide our services. However, we may share anonymous, aggregated data with trusted third parties for statistical analysis or marketing purposes.

        </p>
        <h5>Your Rights:</h5>
        <p>

                You have the right to access, correct, or delete any personal information we hold about you. You can also opt-out of receiving marketing communications from us at any time by contacting us or using the unsubscribe link provided in our emails.
        </p>
        <h5> Changes to this Privacy Policy:</h5>
        <p>

                We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting on this page. We encourage you to review this Privacy Policy periodically for any updates.

                By using our website, you consent to the collection and use of your information as described in this Privacy Policy. If you have any questions or concerns about our privacy practices, please contact us.
        </p>
        <h5>Our Contacts</h5>
        <h5>Emails:</h5>
        <h5>Phone number:</h5>
        <h5>Address</h5>
         <p>This Privacy Policy was last updated on 15feb2024. </p>
         
    </div>
  )
}

export default privacy