import React from 'react'
import './savecard.css'

const savecard = () => {
  return (
    <div className="cards">
      <div className="Card">
        <div className="image-holder"></div>
        <div className="card-content">
            <h2>Student Saver: Unlocking the Secrets to Saving Money!</h2>
            <p>Day by day being a student even with only basic need it is becoming more tricky and complex to control the financial insurgency from paying  tution fees to buying.......</p>
            <a href="#">Read More</a>
        </div>
        </div>
        <div className="Card">
        <div className="image-holder"></div>
        <div className="card-content">
            <h2>Money Mastery: Slash Bills in Half with 10 Unconventional Ways!</h2>
            <p>Feeling tired of being stuck in payment loop of your bills? Let's take control of your billing finances and utilize our platform and make.......</p>
            <a href="#">Read More</a>
        </div>
        </div>
        <div className="Card">
        <div className="image-holder"></div>
        <div className="card-content">
            <h2>The Millennial's Guide to Saving Big: Your One Click Savings App</h2>
            <p>In the time of technological empowerment equip yourself with powerful tools only suited for your personal financial need which.......</p>
            <a href="#">Read More</a>
        </div>
        </div>
    </div>
  )
}

export default savecard