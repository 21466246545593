import React from 'react'
import './save.css'
import Navbar from '../Navbar/nbar'
import Savecard from './cards/savecard'
import Image1 from '../../assets/sgssa/1.png'
import Image2 from '../../assets/sgssa/2.png'
import Image3 from '../../assets/sgssa/3.png'
import Image4 from '../../assets/sgssa/4.png'





const save = () => {
  return (
    <section id='#save'>
      < Navbar />
    <div className="container save__container">
        <div className='saveLeft'>
          <h1> Spend More Save more</h1>
          <p>
          Saving money doesn't have to mean cutting back on the things you love. In fact, with the right approach, you can spend more strategically and still build a healthy savings account. The key lies in harnessing cutting-edge technology to unlock new possibilities for efficient saving. Let's embark on a journey to discover how advanced technology can revolutionize the way we think about spending and saving, all while gaining financial stability.
          </p>
          
        </div>

        <div className='saveRight'>
          <article className='card1'>
            <img src={Image1} alt='analyse'/>
          </article>
          <article className='card2'>
            <img src={Image2} alt='highlight'/>
          </article>
          <article className='card3'>
            <img src={Image3} alt='manage'/>
          </article>
          <article className='card4'>
            <img src={Image4} alt='achieve'/>
          </article>

        </div>

        
    </div>
    <Savecard />
    
   </section>
  ) 
}

export default save