import React from 'react'
import { Link } from 'react-router-dom';
import './Footer.css'
import {FaFacebookF} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'
import { AiOutlineLinkedin } from 'react-icons/ai'


const footer = () => {
  return (
    <footer>
      <ul className='permalinks'>
         <li><a href="#">Home</a></li>
         <li><a href="/individual">Individual</a></li>
         <li><a href="/business">Business</a></li>
         <li><a href="#faqs">FAQ</a></li>
         <li><a href="#contact">ContactUs</a></li>
      </ul>
      <Link to="/term" className='terms'>Terms & conditions</Link>

      <div className="footer__socials">
        <a href="https://www.facebook.com/people/SomFin/61556038953638/"><FaFacebookF/></a>
        <a href="https://www.instagram.com/somfinofficial/"><FiInstagram/></a>
        <a href="https://www.linkedin.com/company/somfin/"><AiOutlineLinkedin/></a>
      </div>
      <div className="footer__copyright">
        <Link to="/privacy">Privacy Policy</Link> &nbsp;
        <small>&copy; SOMFIN. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default footer