import React from 'react'

const termcond = () => {
  return (
    <div className='container termcond__container'>
      
        <h2>TERMS AND CONDITIONS for SOMFIN</h2>
        <h5>LAST UPDATED: 15 FEB 2024</h5>
        <p>
        By accessing and using the SOMFIN website, you agree to comply with the following terms and conditions:

        <h5>1. Use of Website:</h5>

        You agree to use the SOMFIN website solely for lawful purposes and in accordance with these terms and conditions.
        You must not use the website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website.

        <h5>2. Intellectual Property:</h5>

        The content, design, and layout of the SOMFIN website are protected by copyright and other intellectual property rights.
        You may not reproduce, distribute, or use any content from the website without prior written permission from SOMFIN.

        <h5>3. User Responsibilities:</h5>

        You are responsible for maintaining the confidentiality of any login information associated with your account on the SOMFIN website.
        You agree to provide accurate and up-to-date information when using any interactive features or submitting feedback through the website.

        <h5>4. Limitation of Liability:</h5>

        SOMFIN shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your use of the website.
        We make reasonable efforts to ensure the accuracy, completeness, and reliability of the information and content provided on the website. However, we cannot guarantee or warrant that all content is always accurate, complete, or reliable. Users should use their discretion and verify any information obtained from the website before relying on it for any purpose.

       <h5> 5. Third-Party Links:</h5>

        The SOMFIN website may contain links to third-party websites or resources. These links are provided for your convenience and do not signify endorsement or approval by SOMFIN.
        We are not responsible for the content or practices of third-party websites, and your use of such websites is at your own risk.

       <h5> 6. Modification of Terms:</h5>

        SOMFIN reserves the right to modify or update these terms and conditions at any time without prior notice.
        Your continued use of the website after any changes to the terms constitutes acceptance of the revised terms.

        <h5>7. Governing Law:</h5>

        These terms and conditions shall be governed by and construed in accordance with the laws of Information Technology Act, 2000, without regard to its conflict of law provisions.
        Any disputes arising out of or relating to these terms shall be subject to the exclusive jurisdiction of the courts inInformation Technology Act, 2000.

        <h5>8. Severability:</h5>

        If any provision of these terms and conditions is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.

        By using the SOMFIN website, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you should not use the website.

        If you have any questions or concerns about these terms and conditions, please contact us .
        <h5>
        CONTACT
        Email
        Phone number
        Address
       </h5>

        These terms and conditions were last updated on [15 FEB 2024].

        </p>
    </div>
  )
}

export default termcond