import React from 'react'
import'./sectionhead.css'

const SectionHead= ({ icon, title, className}) => {
    return(
        <div className="section__head">
            <h2>{title}</h2>
            <span>{icon}</span>
            
        </div>
    )
}

export default SectionHead