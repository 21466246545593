import React from 'react';
import './mainpg.css'; // Assuming you have a CSS file named mainpg.css in the same directory
import videoFile from '../../../assets/mainlvd/landdvd1.mp4';

const MainPg = () => {
  const handleLearnMoreClick = (event) => {
    event.preventDefault(); 
    window.location.href = '/save'; 
  };

  return (
    <div className='container mainpg__container'>
      <div className='mainpg__vdo'>
        <video autoPlay muted loop src={videoFile} alt="landing"></video>
      </div>
      <div className='mainpg__text'>
        <h1>
          Single stop for<br/>
          all of yours 
          Finances.
        </h1>
        <h2>
          Explore and experience the independent journey<br/> you always imagine.
        </h2>
        <a href="/save" className="btn11" onClick={handleLearnMoreClick} >
        
          <div className="circle">
            <div className="arrow"></div>
          </div>
          <span className="text">Learn More</span>
        </a>
        <a href="/planner" className='btn12'>Download your financial roadmap for free</a>
      </div>
    </div>
  );
}

export default MainPg;
