import React, { useRef } from 'react';
import './Contact.css';
import { MdOutlineEmail } from 'react-icons/md';
import { AiOutlineLinkedin } from 'react-icons/ai';
import { FaWhatsapp } from 'react-icons/fa';
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_2idz8kw', 'template_z0bju65', form.current, 'qSp_483cmAMmnfRWN')
    
    e.target.reset()
  };

  return (
    <section id='contact'>
      <h5 style={{
        color: '#4f297e',
        fontSize: '1.2rem',
      }}>Get In Touch</h5>
      <h2 style={{
        color:'#4d0d8a',
        fontSize:'2.5rem',
      }}>Contact Us</h2>
      <div className='container contact__container'>
        <div className='contact__options'>
        
          <article className='contact__option'>
            <MdOutlineEmail className='contact__options-icon' />
            <h4>Email</h4>
            <h5>somfin@smail.iitm.ac.in</h5>
            <a href='mailto:somfin@smail.iitm.ac.in' target='_blank' rel='noopener noreferrer'>
              Send A message
            </a>
          </article>
          <article className='contact__option'>
            <AiOutlineLinkedin className='contact__options-icon' />
            <h4>LinkedIn</h4>
            <h5>SOMFIN</h5>
            <a href='https://www.linkedin.com/company/somfin/' target='_blank' rel='noopener noreferrer'>
              Send A message
            </a>
          </article>
          <article className='contact__option'>
            <FaWhatsapp className='contact__options-icon' />
            <h4>Whatsapp</h4>
            <h5>+91 (858) 301 1212</h5>
            <a href='https://api.whatsapp.com/send?phone=8583011212' target='_blank' rel='noopener noreferrer'>
              Send A message
            </a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input type='text' name='name' placeholder='Your Full Name' required />
          <input type='email' name='email' placeholder='Your Email' required />
          <textarea name='message' rows='7' placeholder='Your Message' required />
          <button type='submit' className='btn btn-primary'>
            Send Message
          </button>
        </form>
  

      </div>
      
    </section>
  );
};

export default Contact;