import React from 'react'
import Navbar from '../../Navbar/nbar'
import './business.css'
import Image011 from'../../../assets/achieve/businesmainpg.png'
import styled, { keyframes, css } from 'styled-components';
const analyze = () => {
  const row1 = [
    require('../../../assets/featimg/bud.png'),
    require('../../../assets/featimg/ana.png'),
    require('../../../assets/featimg/rep.png'),
    require('../../../assets/featimg/lit.png'),
    require('../../../assets/featimg/goal.png'),
    require('../../../assets/featimg/plan.png'),
    
  ];

  const row2 = [
    require('../../../assets/featimg/tol.png'),
    require('../../../assets/featimg/upd.png'),
    require('../../../assets/featimg/suup.png'),
    require('../../../assets/featimg/man.png'),
    require('../../../assets/featimg/sec.png'),
    require('../../../assets/featimg/inv.png'),
  ];
  return (
    <div className="analyze__container">
      <Navbar></Navbar>
      <div className='text_container'>
        <h12>kick start your <i>Business </i>now!</h12>
        <h2>Empower every opportunity of the market.<br/>We provide you powerful tools to <br/>identify and imply the trends.</h2>
      </div>
      <div className='mainimg'>
      <img id="imgonee" src={Image011} alt="Image4" /> 
        
      </div>
        <a href="/contact" className="button2">
                <span></span>
                Connect With Us
                <span></span>
            </a>
      

    <div className='container feat_container'>
      <div className='wrapper'>
        <div className='text_feat'>
          <h8>Features</h8>
          <h8>Features</h8>
          <h7 >for Future</h7>
        </div>
        <div className='note__feat'>Our customer is guaranteed to experience the ultimate capability of a platform for every aspect of finances from gaining Financial literacy to achieving any financial goals one can ever imagine.</div>
       
       
        
       
       
       
       
       
       
       
       
       
       
       
        <Marquee>
          <MarqueeGroup>
            {row1.map((el) => (
              <ImageGroup key={el}>
                <Image src={el} />
              </ImageGroup>
            ))}
          </MarqueeGroup>
          <MarqueeGroup>
            {row1.map((el) => (
              <ImageGroup key={el}>
                <Image src={el} />
              </ImageGroup>
            ))}
          </MarqueeGroup>
        </Marquee>
        <Marquee>
          <MarqueeGroup2>
            {row2.map((el) => (
              <ImageGroup key={el}>
                <Image src={el} />
              </ImageGroup>
            ))}
          </MarqueeGroup2>
          <MarqueeGroup2>
            {row2.map((el) => (
              <ImageGroup key={el}>
                <Image src={el} />
              </ImageGroup>
            ))}
          </MarqueeGroup2>
        </Marquee>
        






        
       
       
      </div>
    </div>
      
    
          
    </div>
  )
}

export default analyze


const Marquee = styled.div`
  display: flex;
  width: 1500px;

  overflow: hidden;
  user-select: none;

  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );

  @media screen and (max-width: 600px) {
  
    width: 400px; /* Adjusted width for smaller screens */
    top: -5rem;
  }

  @media screen and (max-width: 1024px) {
  
    width: 100vw;

  }



  `;

const scrollX = keyframes`
  from {
    left: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width:80%;
  animation: ${scrollX} 30s linear infinite;
  

  @media screen and (max-width: 600px) {
    width: 900px; 
    
  }
  
  @media screen and (max-width: 1024px) {
    width: 1200px; 
    
  }


`;

const MarqueeGroup = styled.div`
  ${common}
`;
const MarqueeGroup2 = styled.div`
  ${common}
  animation-direction: reverse;
  animation-delay: -3s;
`;

const ImageGroup = styled.div`
  display: grid;
  place-items: center;
  width: clamp(10rem, 1rem + 40vmin, 30rem);
  padding: calc(clamp(10rem, 1rem + 30vmin, 30rem) / 10);
`;

const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 80%;
  border-radius: 0.5rem;
  aspect-ratio: 16/9;
  padding: 5px 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;
