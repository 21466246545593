import React from 'react';
import './grow.css';
import GrowImg1 from"../../../assets/pages/grow/growimg1.png"
import Navbar from '../../Navbar/nbar';

const Grow = () => {
  return (
    <div className="groww_container">
      <div className="grow_container">
        <div className="grow_head1">
          <Navbar></Navbar>
          <h5>Hello Everyone</h5>
          <h2>Let's Go</h2>
         
        </div>
        <div className="grow_image1">
         <div className="image1">
           <img src={GrowImg1} alt="GrowImage" />
         </div>
        </div>
      </div>  
    </div>
  );
};

export default Grow;