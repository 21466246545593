import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
import SavePage from'./components/sgsaa/save'
import Privacy from './components/Term&pri/priv/privacy'
import Termcond from './components/Term&pri/termcon/termcond'
import Grow from './components/pages/grow/grow'
import Individual from './components/pages/secure/individual'
import Business from './components/pages/Analyze/business'
import Contact from './components/contact/Contact'
import Planner from'./components/pages/plaanerform/plaaneerfrm'


import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";

  const router = createBrowserRouter([
    {
      path: "/",
      element: <App/>,
    },
    {
        path: "save",
        element: <SavePage/>,
      },
   {
      path: "privacy",
      element: <Privacy/>,
    },
    {
      path: "term",
      element: <Termcond/>,
    },
    {
      path: "grow",
      element: <Grow/>,
    },
    {
      path: "individual",
      element: <Individual/>,
    },

    {
      path: "business",
      element: <Business/>,
    },
    {
      path: "contact",
      element: <Contact/>,
    },
    {
      path: "planner",
      element: <Planner/>,
    },

    
    
    

  ]);
  


ReactDOM.render(<RouterProvider router={router}/>, document.querySelector("#root"))
