import React from 'react'
import Navbar from '../../Navbar/nbar'
import { Link } from 'react-router-dom';
import Footer from '../../footer/Footer'

import './individual.css'
import videoFile1 from '../../../assets/featvid/adv.mp4';
import videoFile2 from '../../../assets/featvid/privat.mp4';
import videoFile3 from '../../../assets/featvid/simple.mp4';



const secure = () => {
  return (
    <div className="secure__container">
        <Navbar></Navbar>
        
        <div className="secure_p1">
          <h1>Make each penny work for you! <br/>Limit the limits and gain control.</h1>
          

        </div>
        <div className='absolute'>
          <div className='absolute inset-0 justify-center'>
            <div className='bg-shape1 bg-teal opacity-50 bg-blur'></div>
            <div className='bg-shape2 bg-primary opacity-50 bg-blur'></div>
            <div className='bg-shape1 bg-purple opacity-50 bg-blur'></div>
          </div>

        </div>
        <a href="/contact" className="button1">
                <span></span>
                Start Now
                <span></span>
            </a>
        <a href="/planner" className="btn32">
                <span></span>
                Download your financial roadmap for free
                <span></span>
            </a>
        <div class="card__container">
            <article class="card__article">
             <video autoPlay muted loop src={videoFile1} alt="landing" class='card__img'></video>

               <div class="card__data">
                  <span class="card__description">Financial decision</span>
                  <h2 class="card__title">Accurate and Fast</h2>
                  <a href="#" class="card__button">Read More</a>
               </div>
            </article>

            <article class="card__article">
            <video autoPlay muted loop src={videoFile2} alt="landing" class='card__img'></video>

               <div class="card__data">
                  <span class="card__description">Personal and risk proof</span>
                  <h2 class="card__title">Quick Support</h2>
                  <a href="#" class="card__button">Read More</a>
               </div>
            </article>

            <article class="card__article">
            <video autoPlay muted loop src={videoFile3} alt="landing" class='card__img'></video>
               <div class="card__data">
                  <span class="card__description">Extra opportunities</span>
                  <h2 class="card__title">Future Secured</h2>
                  <a href="#" class="card__button">Read More</a>
               </div>
            </article>
          </div>
          
    </div>
   
  )
}

export default secure