import React from 'react';
import Header from './components/header/Header';
import Nav from './components/nav/Nav';
import Navbar from'./components/Navbar/nbar';
import Mainpg from './components/header/mainpgvd/mainpg';
import Homepag from './components/header/homepage/Homepag';
import Feat from'./components/header/featurestrip/feat';
import About from './components/about/About';
import Experience from './components/experience/Experience';
import Services from './components/services/Services';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import FAQ from './components/FAQ/FAQs';
import Thirdpg from './components/thirdpg/thirdpg';
import Fourth from './components/fourthpg/fourth';

const App = () => {
  return (
      <>
        <Navbar />
        <Mainpg />
        <Homepag />
        <Thirdpg />
        <Fourth />
        <FAQ />
        <Contact />
        <Footer />
      </>
  );
};

export default App;
