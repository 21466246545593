export const faqs = [
   {
       id: 1,
       question: "What is SOMFIN?",
       answer: "SOMFIN is a digital financial platform providing personalised financial literacy and management services. We help you grow financially in a secured base where you attain sustainability, stability and literacy financially. Moreover, we provide you a hassle free experience by automating most of your daily financial activities ranging from management of daily budget and bills to reaching financial goals like loan, insurance, assets, etc."
   },
   
   {
    id: 2,
    question: "Do I need to provide my banking details?" ,
    answer: "No. Banking details will not be required primarily."
   },
   
   {
    id: 3,
    question: "Do I need KYC?",
    answer: "Yes. In order to maintain authentication, KYC is required."
   },
   
   {
    id: 4,
    question: "Does it provide investment services?",
    answer: "We analyse your financial architecture and provide you the investing options which are best suited for you."
   },
   
   {
    id: 5,
    question: "What is the pricing for SOMFIN?",
    answer: "There is a two-level subscription model, namely premium and diamond subscriptions. Each level caters a different level of analysis and experience is customised accordingly."
    },
    {
        id: 6,
        question: "How to use the services of SOMFIN?",
        answer: "SOMFIN provides application-based services. Currently we are in the development phase and once launched, one can install our application in order to start their journey."
    },
    

   

    





]