import React, { useState } from 'react';
import { init, send } from 'emailjs-com';
import './planeerrem.css'; // Ensure this has the updated styles
import Navbar from '../../Navbar/nbar';

init("qSp_483cmAMmnfRWN"); // Replace with your EmailJS user ID

function PlaaneerFrm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isBetaTester, setIsBetaTester] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setEmailError('Kindly fill in the correct email address.');
      return;
    } else {
      setEmailError('');
    }

    const templateParams = {
      name,
      email,
    };

    try {
      const response = await send('service_2idz8kw', 'template_z0bju65', templateParams);
      console.log('Email sent successfully!', response);
      setIsSubmitted(true);
      setSuccessMessage('Participation noted successfully!');
      setName('');
      setEmail('');
    } catch (error) {
      console.error('Failed to send email:', error);
      setEmailError('There was an error sending your email. Please try again later.');
    }
  };

  return (
    <div className="plaaneer-dashboard">
      <Navbar />
      <div className="plaaneer-color"></div>
      <div className="plaaneer-color"></div>
      <div className="plaaneer-color"></div>
      
      <div className="plaaneer-box">
        {/* Combined Squares */}
        <div className="square" style={{ '--i': 1 }}></div>
        <div className="square" style={{ '--i': 2 }}></div>
        <div className="square" style={{ '--i': 3 }}></div>
        <div className="square" style={{ '--i': 4 }}></div>
        <div className="square" style={{ '--i': 5 }}></div>
        <div className="square" style={{ '--i': 6 }}></div>
        <div className="square" style={{ '--i': 7 }}></div>
        <div className="square" style={{ '--i': 8 }}></div>
        <div className="square" style={{ '--i': 9 }}></div>
        <div className="square" style={{ '--i': 10 }}></div>
        <div className="square" style={{ '--i': 11 }}></div>
      </div>

      <div className="plaaneer-form-container">
        <h38>Welcome</h38>
        <h23>to the family.</h23>
        <form onSubmit={handleSubmit} className="plaaneer-form">
          <label className="plaaneer-label">
            Name:
            <input 
              type="text" 
              value={name} 
              onChange={(e) => setName(e.target.value)} 
              required 
            />
          </label>
          <label className="plaaneer-label">
            Email Address:
            <input 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
          </label>
          {emailError && <p className="plaaneer-error-message">{emailError}</p>}
          <label className="plaaneer-label">
            <input 
              type="checkbox" 
              checked={isBetaTester} 
              onChange={(e) => setIsBetaTester(e.target.checked)} 
            />
            Check it to join first 100 early user perks!
          </label>
          <button type="submit">Submit</button>
          <h2>Click Submit to Download Your <br /> Personal Financial Planner for Free.</h2>
        </form>
        {isSubmitted && (
          <div className="plaaneer-download-section">
            <a 
              href="https://drive.google.com/file/d/1LGa4kFDUblDtpgjMk9ayniFpX0nyLz3s/view?pli=1" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="plaaneer-download-button"
            >
              Download Document
            </a>
            <p className="plaaneer-success-message">{successMessage}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default PlaaneerFrm;
