import React, { useEffect } from 'react';
import './fourth.css'
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Imgg1 from '../../assets/fourthpg/one.png'
import Imgg2 from '../../assets/fourthpg/three.png'
import Imgg3 from '../../assets/fourthpg/two.png'
import Imgg4 from '../../assets/fourthpg/five.png'
import Imgg5 from '../../assets/fourthpg/six.png'



const Fourthpg = () => {
    useEffect(() => {
      gsap.registerPlugin(ScrollTrigger); 
  
      var tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".part-2",
          start: "0% 50%",
          end: "50% 50%",
          scrub: 1,
         
        }
      });
      
      tl.to("#imgthree", 
        {
            bottom: "-3vh",
        })
        .to("#imgtwo, #imgfour", 
        {
            bottom: "-15vh",
        })
        .to("#imgone, #imgfive", 
        {
            bottom: "-27vh",
        });

    }, []);
    
  return (
    <div className='fourthpg_container'>
        <div className='part-2'>
        <img id="imgone" src={Imgg1} alt="Image1" /> 
        <img id="imgtwo" src={Imgg2} alt="Image2" /> 
        <img id="imgthree" src={Imgg5} alt="Image3" /> 
        <img id="imgfour" src={Imgg3} alt="Image4" /> 
        <img id="imgfive" src={Imgg4} alt="Image4" /> 


        </div>
    </div>
  )
}

export default Fourthpg